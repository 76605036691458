import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import Cookies from 'universal-cookie';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Home";
import NotFound from "./NotFound"
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Success from './pages/success/Success'
import Verification from './pages/verification/Verification'
import { useHistory } from "react-router-dom";


function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const cookies = new Cookies();

  let history = useHistory();

  function handleLogout() {
    cookies.remove('token')
    userHasAuthenticated(false);
  }

  useEffect(() => {
    onLoad();
  }, []);


  async function onLoad() {
    try {

      if (cookies.get('token') != null) {
        userHasAuthenticated(true);
      }
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  return (
    <BrowserRouter>
      <div className="App container">
        <Switch>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/login" >
            <Login history={history}/>
          </Route>
          <Route exact path="/main">
            <Home/>
          </Route>
          <Route exact path="/success">
            <Success />
          </Route>
          <Route exact path="/verification">
            <Verification />
          </Route> 
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}
export default App;


{/* <Navbar collapseOnSelect bg="light" expand="md" className="mb-3">
<LinkContainer to="/">
<Navbar.Brand className="font-weight-bold text-muted">
  Nelsonly
</Navbar.Brand>
</LinkContainer>
<Navbar.Toggle />
<Navbar.Collapse className="justify-content-end">
<Nav activeKey={window.location.pathname}>
  {isAuthenticated ? (
    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
  ) : (
    <>
      <LinkContainer to="/signup">
        <Nav.Link>Signup</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/login">
        <Nav.Link>Login</Nav.Link>
      </LinkContainer>
    </>
  )}

</Nav>
</Navbar.Collapse>
</Navbar> */}