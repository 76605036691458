import React from "react";
import "./Success.css";

export default function Success() {
  return (
    <div className="Success">
      <div className="lander">
        <h1>Created!</h1>
        <p className="text-muted">Please follow confirmation steps in email!</p>
      </div>
    </div>
  );
}