import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Signup.css";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton"
import { onError } from "../../libs/errorLib";
import axios from 'axios';
import { useHistory, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import lock from '../../images/lock.svg'
import {Container, Row, Col} from "react-bootstrap"
import Input from '../../components/Input'


class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      confirmpassword: "",
      userHasAuthenticated: false,
      isLoading: false,
      validatedForm: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cookies = new Cookies()
    
  }



  handleSubmit() {
    this.setState({isLoading:true});
    try {
      axios({
        method: 'post',
        url: 'https://api.auth.nelsonly.com/CreateUser',
        data: {
          "email": this.state.email,
          "password": this.state.password
        }
      }).then(res => {
        this.setState({isLoading:false});
        if (res.data['created'] === true) {
          this.setState({updatePath:"/success"})
        } else if (res.data['created'] === false) {
          onError("Please contact an Administrator")
        }
      })
    } catch (e) {
      onError(e);
      this.setState({isLoading:false});
    }

  }

  render(){
  return (
    <div className="Login">
      <Container fluid> 
        <Row className="m-2">
          <Col> 
            <img src={lock} style={{width:"70px"}} alt="Logo" />      
          </Col>
        </Row>
        <Row>
          <Col>
            Create a Nelsonly Account
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="email"
              label="Email Address"
              type="email"
              locked={false}
              active={false}
              changeHandler={(val)=>{this.setState({'email':val})}}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              id="password"
              label="Password"
              type="password"
              locked={false}
              active={false}
              changeHandler={(val)=>{this.setState({'password':val})}}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Input
              id="confirmpassword"
              label="Confirm Password"
              type="password"
              locked={false}
              active={false}
              changeHandler={(val)=>{this.setState({'confirmpassword':val})}}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <LoaderButton
              block
              size="lg"
              type="submit"
              isLoading={this.state.isLoading}
              disabled={false}
              onClick={()=>{this.handleSubmit()}}
            >
              Login
            </LoaderButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <p id="or"> 
              <span>
               OR 
              </span>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <LoaderButton               
              className="Clear"
              onClick={() => this.setState({updatePath:"login"})}
            >
              Log In With Existing Account </LoaderButton>
          </Col>
        </Row>


      </Container>
      { this.state.updatePath && 
        <Redirect to={this.state.updatePath} />
      }
    </div>


  );
}

}
export default Login;

