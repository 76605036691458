import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Verification.css";
import { useAppContext } from "../../libs/contextLib";
import LoaderButton from "../../components/LoaderButton"
import { onError } from "../../libs/errorLib";
import axios from 'axios';
import { Redirect, useHistory } from "react-router-dom";

export default function Verification() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  let history = useHistory();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl)
      const email = params.get('email');
      const token = params.get('verify')
      axios({
        method: 'post',
        url: 'https://api.auth.nelsonly.com/VerifyUser',
        data: {
          "email": email,
          "verify": token
        }
      }).then(res => {
        if (res.data['verified'] === true) {
          setIsSuccess(true)
          history.push("/login");
        } else if (res.data['verified'] === false) {
          onError("Please contact an Administrator")
          history.push("/error-page");
        }
      })
    } catch (e) {
      onError("Please Contact an Administrator");
      setIsFailure()
    }
  }

  return (
    <div className="Verification">
        <div>
          { isSuccess &&
            <Redirect to='/'/>
          }
        </div>
        <div>
          { isFailure &&
            <Redirect to='/'/>
          }
        </div>
    </div>
  );
}